.label_container {
    height: 3in;
    width: 5in;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: white;
    border: 1px solid black;
}

.pack_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
    /* border: 1px solid black; */
}

.pack_label {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    /* border: 1px solid black; */
}

.pack_label > div {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-self: center;
    font-size: 1em;
    text-align: left;
    /* border: 1px solid red; */
}

.label_load {
    flex-grow: 1;
    font-size: 1.5em;
    font-weight: bolder;
    /* border: 1px solid black; */
}

.label_vendor{
    flex-grow: 1;
    font-size: 1.5em;
    text-align: center;
    /* border: 1px solid black; */
}

.label_ftg{
    flex-grow: 1;
    font-size: 1.5em;
    text-align: right;
    /* border: 1px solid black; */
}

.pack_details{
    flex-grow: 1;
    font-size: 1em;
    /* border: 1px solid black; */
}

.barcode_container {
    /* flex-grow: 1; */
    /* border: 1px solid red; */
}

.barcode {
    width: 100%;
    height: 100px;
}