.report {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    /* overflow-y: scroll; */
}

.report_page {
    background-color: white;
    width: 8.5in;
    height: 11in;
    display: flex;
    padding: 15px;
    flex-direction: column;
}

.report_header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.load_info {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.load_info_table {
    font-weight: bold;
}

.load_data {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.load_data_table {
    width: 98%;
    border-collapse: collapse;
    margin: 0px, 20px, 20px, 0px;
    table-layout: fixed;
}

.load_data_table > tbody > tr > td  {
    text-align: center;
    border: 1px solid black;
}

.load_data_table > tbody > tr :nth-child(1)  {
    width: 75px;
    border: none;
    font-weight: bold;
}

.load_data_table > tbody > tr :nth-last-child(-n+2) {
    font-weight: bold;
}

.load_summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.load_summary_table {
    width: 98%;
    border-collapse: collapse;
    margin-bottom: 10px;
    table-layout: fixed;
    font-weight: bold;
}

.load_summary_table > tbody > tr > td {
    text-align: center;
    border: 1px solid black;
}

.load_summary_table > tbody > tr :nth-child(1)  {
    text-align: left;
    width: 75px;
    font-weight: bold;
}
